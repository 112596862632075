@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Varela+Round&display=swap");

.Home-Title {
    color: white;
    font-family: "Varela Round", sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 45px;
}

.Home-Pfp {
    height: 256px;
    width: 256px;
    padding: 0px;
    border-radius: 30px;
    margin: auto;
}

.Home-Socials img {
    height: 4rem;
    transition: transform 0.3s;
}

.Home-Socials img:hover {
    transform: translateY(-10px);
}

.Home-Stats {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    margin-bottom: 10px;
    text-decoration: none;
    color: white;
    cursor: default;
    opacity: 0;
    transition: opacity 0.4s;
}

.Home-Stats-Visible {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    margin-bottom: 10px;
    text-decoration: none;
    color: white;
    cursor: default;
    
}
